import * as Yup from 'yup'
import useIndustriesStore from './store/use-industries-store'

const useFormFieldsOrganization = () => {
  const { items: industries } = useIndustriesStore()

  const allFields = [
    {
      name: 'name',
      label: 'Name',
      placeholder: 'Acme',
      type: 'text',
      validation: Yup.string()
        .required('Name is required')
        .max(50, 'Must be under 50 characters'),
      category: 'basic',
    },
    {
      name: 'website',
      label: 'Company Website',
      placeholder: 'https://acme.com',
      type: 'text',
      validation: Yup.string()
        .url(`Must be a valid URL, including http:// or https://`)
        .max(100, 'Must be under 100 characters'),
      category: 'basic',
    },
    {
      name: 'city',
      label: 'City',
      placeholder: 'Metropolis',
      type: 'text',
      validation: Yup.string().max(50, 'Must be under 50 characters'),
      category: 'basic',
    },
    {
      name: 'state',
      label: 'State',
      placeholder: 'DE',
      type: 'text',
      validation: Yup.string().max(50, 'Must be under 50 characters'),
      category: 'basic',
    },
    {
      name: 'country',
      label: 'Country',
      placeholder: 'USA',
      type: 'text',
      validation: Yup.string().max(50, 'Must be under 50 characters'),
      category: 'basic',
    },
    {
      name: 'industry',
      label: 'Industry',
      type: 'auto',
      options: industries || [],
      validation: Yup.string().required('Industry is required'),
      category: 'basic',
    },
    {
      name: 'team',
      label: 'Team',
      placeHolder: 'Team Awesome',
      helpText:
        'This is only shown internally, to help other teams reach out if they have an opportunity',
      type: 'text',
      validation: Yup.string()
        .max(50, 'Must be under 50 characters')
        .required('Team is required'),
      category: 'settings',
    },
    {
      name: 'email',
      label: 'Contact Email',
      placeHolder: 'client@gregoryfca.com',
      type: 'email',
      helpText:
        'This is the email journalists will use to reach out to you. It is shown publicly.',
      validation: Yup.string()
        .email('Must be a valid email address')
        .required('Email is required'),
      category: 'settings',
    },
    // {
    //   name: 'isPrivate',
    //   label: `Hide from Gregory FCA's internal list of contacts`,
    //   type: 'boolean',
    //   helpText: `The profile will remain publicly available via direct link. Check this box if you don't want any other teams to reach out about this contact, or if they is no long a client.`,
    // },
  ]

  const getFields = category => {
    return allFields.filter(field => field.category === category)
  }

  return { getFields }
}

export default useFormFieldsOrganization
